<template>
<div class="pa-4">
  <v-row no-gutters>
    <v-col md="8" offset-md="2">
    <v-card width="100%">

  <v-row class="pa-4" justify="space-between">
    <v-card-title>Profil de {{ logged_user.first_name }} {{ logged_user.last_name }}</v-card-title>
    <v-dialog v-model="userDialog" max-width="800">
      <template v-slot:activator="{on, attrs}">
        <v-btn class="mt-2 mr-2" v-bind="attrs" v-on="on" color="primary">Modifier son profil</v-btn>
      </template>
      <UserForm @reloadUsers="reloadUser" operation="update" :user="logged_user"/>
    </v-dialog>
  </v-row>

      <v-card-text>
        <span class="body-1 font-weight-bold">Prénom</span><br>
        <span class="body-1">{{ logged_user.first_name }}</span><br><br>

        <span class="body-1 font-weight-bold">Nom</span><br>
        <span class="body-2">{{logged_user.last_name}}</span><br><br>

        <span class="body-1 font-weight-bold">Email</span><br>
        <span class="body-2">{{ logged_user.email }}</span><br><br>

        <span class="body-1 font-weight-bold">Groupe</span><br>
        <span class="body-2">{{ logged_user.group }}</span><br><br>
      </v-card-text>
    </v-card>
  </v-col>
  </v-row>
</div>
</template>

<script>
import UserForm from "@/views/UserForm";
export default {
  name: "Profil",
  components: {UserForm},
  data() {
    return {
      userDialog: false,
    };
  },
  computed: {
    logged_user() {
      return this.$store.getters["auth/getLoggedUser"];
    }
  },
  methods: {
    async reloadUser() {
      this.userDialog = false;
      let result = await this.$store.dispatch("auth/refresh");
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Utilisateur mis à jour")
      }

    }
  }
}
</script>

<style scoped>

</style>