<template>
  <v-card flat elevation="0" >
    <v-toolbar color="primary">
      <v-toolbar-title v-if="operation === 'create'" class="white--text">Créer l'utilisateur</v-toolbar-title>
      <v-toolbar-title v-if="operation === 'update'" class="white--text">Mettre à jour l'utilisateur</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="userForm">
        <v-text-field label="Nom d'utilisateur" v-model="userModel.username"></v-text-field>
        <v-text-field label="Prénom" v-model="userModel.first_name"></v-text-field>
        <v-text-field label="Nom" v-model="userModel.last_name"></v-text-field>
        <v-text-field label="Email" type="email" v-model="userModel.email"></v-text-field>
        <v-text-field label="Téléphone mobile" v-model="userModel.tel_mobile"></v-text-field>
        <v-text-field label="Nom de société" v-model="userModel.nom_societe"></v-text-field>
        <v-select label="Groupe" v-model="userModel.group" :items="groups" item-text="name" item-value="id"></v-select>
        <v-checkbox v-if="operation === 'update'" v-model="changePassword" label="Changer le mot de passe"></v-checkbox>
        <v-text-field :disabled="!changePassword" label="Mot de passe" type="password" v-model="password"></v-text-field>
        <v-text-field :disabled="!changePassword" label="Confirmer le mot de passe" type="password" v-model="passwordConfirmation"></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="createUser" v-if="operation === 'create'">Créer l'utilisateur</v-btn>
      <v-btn color="primary" @click="updateUser" v-if="operation === 'update'">Mettre à jour l'utilisateur</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'UserForm',
  props: {
    operation: String,
    user: Object,
  },
  async created() {
    this.groups = await this.$store.dispatch("groups/fetchGroups");
    let corresponding_group = this.groups.find((group) => group.name === this.user.group);
    if(corresponding_group !== null) {
      this.userModel.group = corresponding_group.id;
    }
  },
  data() {
    let emptyUser = {
        first_name: null,
        last_name: null,
        group: null,
        email: null,
        tel_mobile: null,
        nom_societe: null,
      }
    return {
      userModel: this.operation === "update" ? JSON.parse(JSON.stringify(this.user)) : emptyUser,
      password: null,
      passwordConfirmation: null,
      changePassword: this.operation === 'create',
      groups: [],
    };
  },
  computed: {},
  methods: {
    async updateUser() {
      if(this.changePassword && this.operation === 'update') {
        this.userModel.password = this.password;
      }
      let result = await this.$store.dispatch("users/putUser", this.userModel);
      if (result !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Utilisateur mis à jour");
      }
      this.$emit("reloadUsers");
    },
    async createUser() {
      this.userModel.password = this.password;
      let result = await this.$store.dispatch("users/postUser", this.userModel);
      if (result !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Utilisateur créé.");
      }
      this.$emit("reloadUsers");
    }
  },


}
</script>
